<template>
  <div>
    <van-nav-bar
      title="故障"
      left-arrow
      :border="false"
      fixed
      @click-left="onClickLeft"
    >
      <template #right>
        <van-icon
          v-if="!$route.params.id"
          size="24"
          name="iconfont-xinzengfengxian"
          class-prefix="iconfont"
          class="iconfont-xinzengfengxian"
          @click="addDeviceFault()"
        />
      </template>
    </van-nav-bar>
    <div class="header">
      <div class="filter-box-wrapper">
        <van-search
          v-model="query.description"
          placeholder="请输入故障名称或关键字"
          class="search-bar"
          @search="onSearch"
          @clear="onCancel"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
        <div ref="filterBox" class="filter-box">
          <span
            v-for="(item, index) in filterList"
            ref="filterItems"
            :key="index"
            class="filter-item"
            :class="[item.type, { active: item.isActive || item.text }]"
            @click="selectCon(item.popupKey)"
            >{{ item.text || item.name }}</span
          >
        </div>
        <div class="search-condition">
          <div class="alarm-total">共{{ total }}个结果</div>
        </div>
      </div>
    </div>
    <div class="content">
      <!-- @scroll.native="onScroll" -->
      <van-pull-refresh
        ref="vanList"
        v-model="refreshing"
        class="alarm-list"
        @refresh="onRefresh"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          :error.sync="loadError"
          error-text="加载失败，请稍后再试！"
          @load="onLoad"
        >
          <div
            v-for="item in list"
            :key="item.id"
            class="alarm-item"
            @click="gotoDetail(item)"
          >
            <div class="alarm-header">
              <div class="alarm-title">
                <div class="alarm-name-box">
                  <div class="name-text-box">
                    <span class="alarm-name">{{ item.description }}</span>
                    <span v-if="item.ticketWorkNo" class="concat"
                      >已关联工单</span
                    >
                  </div>
                </div>
                <div class="status-box">
                  <van-icon
                    name="guzhangleixing"
                    class-prefix="iconfont"
                    class="iconfont-guzhangleixing"
                    size="16"
                  />
                  <span class="equipment-name">{{ item.typeName || "-" }}</span>
                </div>
              </div>
              <div class="alarm-status">
                <van-icon
                  :name="statusIconSwitch(item.status)"
                  class-prefix="iconfont"
                  :class="`iconfont-${statusIconSwitch(item.status)}`"
                  size="44"
                />
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <SelectPopupGrade
      v-model="popupVisibleObj[1].value"
      :immediately="false"
      list-key="value"
      :title="popupVisibleObj[1].title"
      :list.sync="popupVisibleObj[1].list"
      :visible="popupVisibleObj[1].visiable"
      @change="v => popupGradeChange(v, 0, 1)"
      @close="popupVisibleObj[1].visiable = false"
    />
    <SelectPopupGrade
      v-model="popupVisibleObj[2].value"
      :immediately="false"
      list-key="value"
      :title="popupVisibleObj[2].title"
      :list.sync="popupVisibleObj[2].list"
      :visible="popupVisibleObj[2].visiable"
      @change="v => popupGradeChange(v, 1, 2)"
      @close="popupVisibleObj[2].visiable = false"
    />
  </div>
</template>

<script>
import {
  getDictDeviceFaultType,
  getDictDeviceFaultStatus,
  getPageDeviceFaults
} from "../../../api/psm/device";

export default {
  name: "DeviceFault",
  data() {
    return {
      dateTime: {
        start: "",
        end: ""
      },
      loading: false,
      finished: false,
      loadError: false,
      refreshing: false,
      query: {
        description: "",
        // startTime: "",
        // endTime: "",
        type: "",
        status: "",
        page: 1,
        size: 10,
        sort: "ctime_desc"
      },
      filterList: [
        {
          name: "故障类型",
          type: "select",
          text: "",
          popupKey: 1,
          isActive: false
        },
        {
          name: "故障状态",
          type: "select",
          popupKey: 2,
          text: "",
          isActive: false
        }
      ],
      popupVisibleObj: {
        1: {
          visiable: false,
          title: "故障类型",
          value: "",
          list: []
        },
        2: {
          visiable: false,
          title: "故障状态",
          value: "",
          list: []
        }
      },
      total: 0,
      list: []
    };
  },
  computed: {
    statusIconSwitch() {
      return status => {
        const obj = {
          0: "daitijiao1",
          1: "daishenhe",
          2: "shenhetongguo"
        };
        return obj[status];
      };
    }
  },
  watch: {
    dateTime: {
      handler(val) {
        console.log("val", val);
        if (val.start && val.end) {
          this.query.startTime = `${val.start}:00`;
          this.query.endTime = `${val.end}:00`;
          this.onSearch();
        }
        if (!val.start && !val.end) {
          this.query.startTime = "";
          this.query.endTime = "";
          this.onSearch();
        }
      },
      deep: true
    }
  },
  beforeRouteEnter(to, from, next) {
    const name = from.name;
    if (name === "DeviceFaultAdd" || name === "DeviceFaultDetail") {
      next(vm => vm.onSearch());
    }
    next();
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.onLoad();
    // window.sessionStorage.removeItem("redirectURL");
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    addDeviceFault() {
      this.$router.push({
        name: "DeviceFaultAdd"
      });
    },
    onSearch(val) {
      this.query.page = 1;
      this.list = [];
      this.total = 0;
      this.getList();
    },
    onCancel() {
      this.onSearch();
      // this.finished = false;
      // this.handleCondition();
    },
    async getList() {
      this.loading = true;
      this.finished = false;
      if (this.refreshing) {
        this.refreshing = false;
      }
      try {
        let params = {
          equipmentRecordId: this.$route.params.id || "",
          ...this.query
        };
        const { list, total } = await getPageDeviceFaults(params);
        this.total = total;
        this.loadError = false;
        this.loading = false;
        if (list && list.length) {
          this.list = this.list.concat(list);
          this.query.page++;
        } else {
          this.finished = true;
        }
      } catch (err) {
        this.loading = false;
        this.finished = true;
        this.loadError = true;
      }
    },
    onLoad() {
      if (this.refreshing) {
        this.list = [];
        this.query.page = 1;
        this.total = 0;
        this.refreshing = false;
      }
      this.getList();
    },
    onRefresh() {
      this.query.page = 1;
      this.list = [];
      this.total = 0;
      this.getList();
    },
    async selectCon(popupKey) {
      let data = [];
      if (popupKey === 1) {
        data = await getDictDeviceFaultType();
      } else {
        data = await getDictDeviceFaultStatus();
      }
      this.popupVisibleObj[popupKey].list = data;
      this.popupVisibleObj[popupKey].visiable = true;
    },
    popupGradeChange(v, filterListIdx, popupKey) {
      const vObj = v.length > 0 ? v[0] : {};
      this.popupVisibleObj[popupKey].value = vObj.value || "";
      this.filterList[filterListIdx].text = vObj.label || "";
      if (popupKey === 1) {
        this.query.type = vObj.value || "";
      } else if (popupKey === 2) {
        this.query.status = vObj.value || "";
      }
      this.onSearch();
    },
    gotoDetail(item) {
      let query = item.ticketWorkNo
        ? { name: item.description, no: item.ticketWorkNo }
        : { name: item.description };
      this.$router.push({
        name: "DeviceFaultDetail",
        params: { id: item.id, equipmentId: this.$route.params.id },
        query
      });
    },
    //返回主页
    onClickLeft() {
      // history.go(-1);
      if (this.$route.params.id) {
        this.$router.push({
          name: "equipmentledger",
          params: {
            id: this.$route.params.id
          }
        });
      } else {
        this.$router.push({ path: "/" });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding: 58px 0 0;
  .search-bar {
    padding-bottom: 0;
  }
  .alarm-search {
    margin: 0;
    .date-time-box {
      padding: 12px 16px 0 16px;
      background-color: #fff;
      .datetime {
        position: relative;
        margin: 0;
      }
    }
  }
  .filter-box {
    padding: 10px 0;
    font-size: 12px;
    white-space: nowrap;
    overflow-x: scroll;
    border-bottom: 1px solid #c7c9d0;
    &::-webkit-scrollbar {
      display: none;
    }
    .filter-item {
      display: inline-block;
      border-radius: 10px;
      background-color: #eaeff5;
      padding: 4px 6px;
      box-sizing: border-box;
      margin: 0 4px;
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #3b4664;
      &.select {
        position: relative;
        padding-right: 18px;
        &::after {
          position: absolute;
          content: "";
          right: 6px;
          top: 8px;
          border: 4px solid transparent;
          border-top-color: #aeb3c0;
        }
        &.active {
          &::after {
            border-top-color: $--color-primary;
          }
        }
      }
      &.active {
        background-color: #dcecff;
        color: $--color-primary;
      }
      &:first-child {
        margin-left: 16px;
      }
      &:last-child {
        margin-right: 16px;
      }
    }
  }
  .search-condition {
    display: flex;
    justify-content: center;
    .loading {
      display: inline-block;
    }
    .van-loading__spinner {
      width: 4vw;
      height: 4vw;
    }
    .alarm-total {
      color: #8c8f97;
      text-align: center;
      font-size: 12px;
      padding: 12px 0 0;
    }
    // .condition-switch {
    //   color: $--color-icon;
    //   i {
    //     font-size: 5vw;
    //   }
    // }
  }
}
.content {
  .van-list {
    height: 73vh;
  }
  .alarm-list {
    flex: 1;
    overflow-y: auto;
    z-index: 9;
    .alarm-item {
      padding: 12px 17px 13px 16px;
      border-bottom: 8px solid #eceef2;
      font-size: 4vw;
      .alarm-header {
        display: flex;
        .alarm-title {
          flex: 3;
          max-width: 70vw;
          // div {
          //   overflow: hidden;
          //   text-overflow: ellipsis;
          //   white-space: nowrap;
          //   line-height: 1.1;
          // }
          color: #3b4664;
          .alarm-name-box {
            display: flex;
            align-items: center;
            margin: 0 0 10px;
            .name-text-box {
              // flex: 1;

              display: flex;
              align-items: center;
              line-height: 20px;
              .alarm-name {
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 50vw;
                height: 20px;
                font-size: 14px;
                font-weight: 500;
                color: #3b4664;
                line-height: 20px;
              }
              .concat {
                display: inline-block;
                margin-left: 10px;
                width: 64px;
                height: 16px;
                opacity: 1;
                background: #9ed05a;
                border-radius: 2px;
                text-align: center;
                line-height: 16px;
                font-size: 10px;
                font-weight: 400;
                color: #ffffff;
              }
            }
            .make-up-tag {
              display: inline-block;
              height: 16px;
              line-height: 16px;
              font-size: 11px;
              padding: 0 6px;
              color: #fff;
              background-color: #3683f4;
              text-align: center;
              border-radius: 3px;
              margin: 0 0 0 8px;
              vertical-align: middle;
            }
          }
          .status-box {
            display: flex;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .equipment-name {
              font-size: 12px;
              font-weight: 400;
              color: #3b4664;
              line-height: 17px;
              margin: 0 20px 0 4px;
            }
            i {
              color: #aeb3c0;
            }
          }
        }
        .alarm-status {
          flex: 1;
          text-align: right;
          .iconfont-daishenhe {
            color: #dea148;
          }
          .iconfont-daitijiao1 {
            color: #8eb433;
          }
          .iconfont-shenhetongguo {
            color: #5c7ba2;
          }
        }
      }
    }
  }
}
</style>
